import React, { Component } from "react";
import ReactDOM from "react-dom";

export default class ScopeOfWorkItemsApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scopeCollections: [],
      isLoading: true,
      quoteVersion: this.props.quote_version
        ? JSON.parse(this.props.quote_version)
        : null,
      quote: this.props.quote ? JSON.parse(this.props.quote) : null,
      quoteVersionID:
        window.location.href.split("/")[
          window.location.href.split("/").length - 1
        ],
      domain: window.location.hostname,
    };
  }

  toggleBulkButton() {
    let cnt = 0;
    $(".item-select-box").each(function () {
      if ($(this).is(":checked")) {
        cnt++;
      }
    });

    if (cnt == 0) {
      $(".apply-button").attr("disabled", "disabled");
      $(".apply-button").text("Please select an item");
    } else {
      $(".apply-button").removeAttr("disabled");
      $(".apply-button").text("Apply to selected items");
    }
  }

  toggleGroupBoxes(data, event) {
    if (event.target.checked) {
      $(`.group-${data.collection_id}`).prop("checked", true);
    } else {
      $(`.group-${data.collection_id}`).prop("checked", false);
    }

    this.toggleBulkButton();
  }

  processAutoComplete(event) {
    $(".line_item_colour").autocomplete({
      minLength: 2,
      source: function (request, response) {
        $.ajax({
          url:
            `/api/v2/quote_versions/line-item-colours?term=` +
            request.term +
            "&per_page=10",
          method: "GET",
          dataType: "json",
          contentType: "application/json",
          success: function (res) {
            let data = res.data;
            if (data != null && data.length > 0) {
              var numberArray = data.map((d) => d.title);
              response(numberArray);
            }
          },
        });
      },
      open: function (event, ui) {
        $("html, body").css({ overflow: "hidden" });
      },
      close: function () {
        $("html, body").css({ overflow: "inherit" });
      },
      position: { my: "left top", at: "left bottom", collision: "flip" },
    });
  }

  componentDidMount() {
    axios.get(`/api/proposal/${this.state.quoteVersionID}`).then((res) => {
      let tempArr = [];
      res.data.scope_collections.forEach((scopeCollection) =>
        tempArr.push(scopeCollection)
      );

      this.setState((preState) => {
        return {
          scopeCollections: tempArr,
          quoteVersion: { ...res.data },
          isLoading: false,
        };
      });
    });
  }

  render() {
    let borderBottom = {
      borderBottom: "1px solid #cec9da",
    };
    let width20 = {
      width: "20%",
    };
    let width15 = {
      width: "15%",
    };
    let width10 = {
      width: "10%",
    };
    let width5 = {
      width: "5%",
    };
    let maxWidth70 = {
      maxWidth: "70px",
    };
    let minWidth32 = {
      minWidth: "32px",
    };
    let height200 = {
      height: "200px",
    };

    const statusName = this.state.quoteVersion.quote_status.name;
    const colourName =
      (statusName == "Pending" || statusName == "Quote Sent") &&
      this.state.quote.job_id == null
        ? "Colour"
        : "";

    const loadingView = this.state.isLoading ? (
      <div className="spinner loading-spinner">
        <div
          className="d-flex align-items-center justify-content-center"
          style={height200}
        >
          <i className="fas fa-spinner fa-spin fa-2x"></i>
        </div>
      </div>
    ) : null;

    return (
      <div className="table-responsive" id="accordion">
        {loadingView}

        {this.state.scopeCollections.map((scopeCollection) => (
          <div key={scopeCollection.id}>
            <h4 className="mt-4 text-red">{scopeCollection.name}</h4>

            {scopeCollection.line_item_collections.map(
              (lineItemCollection, index) => (
                <div key={lineItemCollection.id}>
                  <div style={borderBottom} className="mb-1">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      className={`panel-heading ${
                        index == 0 ? "" : "collapsed"
                      }`}
                      href={`#lineItemCollection_${lineItemCollection.id}`}
                    >
                      <i className="fa fa-chevron-circle-down"></i>
                      <h5 className="mt-4">{lineItemCollection.name}</h5>
                    </a>
                  </div>

                  <div
                    className={`spec-table-wrap panel-collapse collapse ${
                      index == 0 ? "show" : ""
                    }`}
                    id={`lineItemCollection_${lineItemCollection.id}`}
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <th style={width20}>Name</th>
                          <th style={maxWidth70}>Width</th>
                          <th style={maxWidth70}>Height</th>
                          <th style={minWidth32}>QTY</th>
                          <th style={width15}>Unit Of Measure</th>
                          <th style={width10}>Specifications</th>
                          <th style={width5}>
                            <input
                              type="checkbox"
                              className="group-select align-middle d-none ml-2"
                              onClick={this.toggleGroupBoxes.bind(this, {
                                collection_id: lineItemCollection.id,
                              })}
                            />
                          </th>
                          <th style={width20}>{colourName}</th>
                          <th style={width20}>Photos</th>
                        </tr>
                      </thead>

                      <tbody
                        className="line-item-list"
                        data-hash_id={this.state.quoteVersion.hash_id}
                      >
                        {lineItemCollection.line_items.map((lineItem) => (
                          <tr key={lineItem.id}>
                            <td>{lineItem.name}</td>
                            <td>{lineItem.width > 0 ? lineItem.width : ""}</td>
                            <td>
                              {lineItem.height > 0 ? lineItem.height : ""}
                            </td>
                            <td>{lineItem.quantity}</td>
                            <td>{lineItem.unit_of_measure.name}</td>
                            <td>
                              {lineItem.specifications.map((specification) => (
                                <span key={specification.id}>
                                  {specification.code}
                                </span>
                              ))}
                            </td>
                            <td>
                              <input
                                id={`line-item-check-${lineItem.id}`}
                                type="checkbox"
                                className={`align-middle ml-2 d-none item-select-box select-box-${lineItem.id} group-${lineItemCollection.id}`}
                                data-collection-id={lineItemCollection.id}
                                data-select_box_id={lineItem.id}
                                onClick={this.toggleBulkButton.bind(this)}
                              />
                            </td>
                            <td>
                              {colourName == "Colour" ? (
                                <div className="ui-widget">
                                  <div className="d-inline-flex">
                                    <input
                                      name="line_item_colour[]"
                                      defaultValue={
                                        lineItem.colour ? lineItem.colour : ""
                                      }
                                      className={`form-control border-radius-8 line_item_colour line_item-${lineItem.id}`}
                                      data-line_item_id={lineItem.id}
                                      onChange={this.processAutoComplete.bind(
                                        this
                                      )}
                                      placeholder="Input colour"
                                    />
                                    <label
                                      id={`match-text-${lineItem.id}`}
                                      className="match-text align-middle ml-2 d-none"
                                    >
                                      Match Existing
                                    </label>
                                    <label
                                      id={`colour-consult-text-${lineItem.id}`}
                                      className="colour-consult-text align-middle mr-2 d-none"
                                    >
                                      Colour Consultant
                                    </label>
                                  </div>
                                </div>
                              ) : (
                                <span>{lineItem.colour}</span>
                              )}
                            </td>
                            <td>
                              <div className="slick-slider-photos">
                                {lineItem.photos.map((photo) => (
                                  <div key={photo.id}>
                                    {photo.media_type == "image" ? (
                                      <a
                                        href={photo.url}
                                        data-caption={lineItem.name}
                                      >
                                        <img
                                          className="img-fluid"
                                          height="50"
                                          width="50"
                                          src={photo.url}
                                        />
                                      </a>
                                    ) : (
                                      <div
                                        className="video-lightbox"
                                        data-video={photo.url}
                                        data-caption={lineItem.name}
                                      >
                                        <video height="50" width="50">
                                          <source
                                            src={photo.url}
                                            type="video/mp4"
                                          >
                                            Your browser does not support the
                                            video tag.
                                          </source>
                                        </video>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )
            )}
          </div>
        ))}
      </div>
    );
  }
}

if (document.getElementById("scope-of-work-items-app")) {
  const element = document.getElementById("scope-of-work-items-app");
  const props = { ...element.dataset };

  ReactDOM.render(
    <ScopeOfWorkItemsApp {...props} />,
    document.getElementById("scope-of-work-items-app")
  );
}
