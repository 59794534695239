import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ClimbingBoxLoader } from "react-spinners";
import styles from "./loading.css";
import Echo from "laravel-echo";
window.Pusher = require("pusher-js");

// Previous money formatter
// const numberWithCommas = (x) => {
//     return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// }

/**
 * New money formatter, Code stolen from:
 * https://stackoverflow.com/questions/149055/how-can-i-format-numbers-as-dollars-currency-string-in-javascript
 */
function formatMoney(n, c, d, t) {
  var c = isNaN((c = Math.abs(c))) ? 2 : c,
    d = d == undefined ? "." : d,
    t = t == undefined ? "," : t,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (j = i.length) > 3 ? j % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}

// Commercial proposal Line items 6-4-21
export default class LineItemsAppCommercial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scopeCollections: [],
      isLoading: false,
      quoteVersion: this.props.quote_version
        ? JSON.parse(this.props.quote_version)
        : null,
      quote: this.props.quote ? JSON.parse(this.props.quote) : null,
      quoteVersionID:
        window.location.href.split("/")[
          window.location.href.split("/").length - 1
        ],
      domain: window.location.hostname,
    };

    this.changeSelectedForScopeCollection =
      this.changeSelectedForScopeCollection.bind(this);
    this.refreshList = this.refreshList.bind(this);
  }

  componentDidMount() {
    this.refreshList();
    this.proposalRepaymentPlanLogic();
  }

  changeSelectedForScopeCollection(scopeCollectionID) {
    // At certain statuses, the quote is read-only and the user cannot change the items.
    if (
      this.state.quoteVersion.quote_status.name != "Pending" &&
      this.state.quoteVersion.quote_status.name != "Quote Sent" &&
      this.state.quoteVersion.job_id != null
    ) {
      return;
    }

    this.setState((preState) => {
      return {
        isLoading: true,
      };
    });
    axios
      .post(`/api/scope_collection/${this.state.quoteVersion.hash_id}`, {
        scopeCollectionID: scopeCollectionID,
      })
      .then((res) => {
        this.refreshList();
      });
  }

  refreshList() {
    this.setState((preState) => {
      return {
        isLoading: true,
      };
    });

    axios.get(`/api/proposal/${this.state.quoteVersionID}`).then((res) => {
      let tempArr = [];

      res.data.scope_collections.forEach((scopeCollection) =>
        tempArr.push(scopeCollection)
      );
      this.setState((preState) => {
        return {
          scopeCollections: tempArr,
          quoteVersion: { ...res.data },
          isLoading: false,
        };
      });
    });
  }

  proposalRepaymentPlanLogic() {
    //let quote_version_id = $(".js-proposal-pdf").data('id');

    window.Echo = new Echo({
      broadcaster: "pusher",
      key: process.env.MIX_PUSHER_APP_KEY,
      cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    });

    window.Echo.channel(`repayment-plan.${this.state.quoteVersion.id}`).listen(
      ".App\\Events\\Backend\\Quote\\QuoteVersionRepaymentPlanUpdated",
      (e) => {
        this.refreshList();
      }
    );
  }

  render() {
    const qbccView = this.state.quoteVersion.qbcc_rate_id ? (
      <div className="row mk-big-comm-quote">
        <div className="col-6">
          <p className="font-16">QBCC:</p>
        </div>
        <div className="col-6">
          <p className="font-16 text-right pb-0">
            $
            <span>{formatMoney(this.state.quoteVersion.showingQBCCPrice)}</span>
            <br />
            <small className="ml-auto qbcc_tooltip">
              <i>
                <a href="#">What's this?</a>
              </i>
            </small>
          </p>
        </div>
      </div>
    ) : null;

    const dynamicPricingView =
      this.state.quoteVersion.discountPrice > 0 ? (
        <>
          <div className="row mk-big-comm-quote">
            <div className="col-6">
              <p className="font-16">SUB-TOTAL:</p>
            </div>
            <div className="col-6">
              <p className="font-16 text-right">
                &nbsp;$
                <span>
                  {formatMoney(this.state.quoteVersion.subTotalPrice)}
                </span>
              </p>
            </div>
          </div>
          <div className="row mk-big-comm-quote">
            <div className="col-6">
              <p className="font-16">DISCOUNT APPLIED:</p>
            </div>
            <div className="col-6">
              <p className="font-16 text-right">
                &nbsp;$
                <span>
                  {formatMoney(this.state.quoteVersion.discountPrice / 100)}
                </span>
              </p>
            </div>
          </div>
          <hr />
        </>
      ) : null;

    const totalPriceView =
      this.state.quoteVersion.dynamic_price > 3000 ? (
        <div className="row mk-big-comm-quote">
          <div className="col-6">
            <h4>TOTAL PROJECT COST:</h4>
          </div>
          <div className="col-6">
            <h4 className="text-right">
              &nbsp;$
              <span id="final-quote-price">
                {formatMoney(this.state.quoteVersion.showingTotalPrice)}
              </span>
            </h4>
          </div>
        </div>
      ) : (
        <div className="mk-other-comm-quote">
          <div className="row">
            <div className="col-6">
              <h4>TOTAL PROJECT COST:</h4>
            </div>
            <div className="col-6">
              <h4 className="text-right">
                &nbsp;$
                <span id="final-quote-price">
                  {formatMoney(this.state.quoteVersion.showingTotalPrice)}
                </span>
              </h4>
            </div>
          </div>
        </div>
      );

    const optionalScopeCollectionsView =
      this.state.scopeCollections.filter(
        (scopeCollection) =>
          scopeCollection.optional && scopeCollection.selected === 0
      ).length !== 0 ? (
        <div className="bg-white row d-flex justify-content-center">
          <div className="col-md-12 pt-5">
            <h3>Optional Items</h3>
            <h4 className="mt-3">
              As per your request the following items have been set aside as
              optional.
            </h4>
            <p className="mt-3">
              You may choose which items you wish to proceed with and your cost
              will be updated accordingly.
            </p>
          </div>

          <div className="w-100"></div>

          <div className="bg-white m-5 col-md-12">
            <OptionalScopeCollectionsList
              scopeCollections={this.state.scopeCollections.filter(
                (scopeCollection) =>
                  scopeCollection.optional && scopeCollection.selected === 0
              )}
              changeSelectedForScopeCollection={
                this.changeSelectedForScopeCollection
              }
              refreshList={this.refreshList}
              quoteVersion={this.state.quoteVersion}
            />
          </div>
        </div>
      ) : null;

    const loadingView = this.state.isLoading ? (
      <div id="overlay">
        <div id="text">
          <ClimbingBoxLoader color={"#4690b4"} loading={this.state.isLoading} />
        </div>
      </div>
    ) : null;

    return (
      <div className="" id="authorise-work">
        <div className="row d-flex justify-content-center">
          {loadingView}

          <div className="px-3 pb-3 pt-0 mt-0 mx-5 col-12">
            <ScopeCollectionsList
              scopeCollections={this.state.scopeCollections.filter(
                (scopeCollection) =>
                  !scopeCollection.optional || scopeCollection.selected === 1
              )}
              changeSelectedForScopeCollection={
                this.changeSelectedForScopeCollection
              }
              refreshList={this.refreshList}
              quoteVersion={this.state.quoteVersion}
            />

            <div className="pt-4">
              <h3>Quote Summary</h3>
            </div>
            <div className="bg-light-blue border-radius-12 p-4">
              <div className="row">
                <div className="col-6">
                  <p className="font-16">GST:</p>
                </div>
                <div className="col-6">
                  <p className="font-16 text-right">
                    ${formatMoney(this.state.quoteVersion.gst)}
                  </p>
                </div>
              </div>

              {qbccView}
              {dynamicPricingView}
              {totalPriceView}
            </div>
          </div>
        </div>
        {optionalScopeCollectionsView}
      </div>
    );
  }
}
// End commercial proposal

class ScopeCollectionsList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let divTop = {
      borderTop: "0 solid",
    };
    let costStyle = {
      borderTop: "0 solid",
      minWidth: 130,
    };
    return (
      <div className="table-responsive comm-prop-quote-table">
        <h3>Scope of Work</h3>
        <table className="table mb-0" id="quote-table">
          <thead>
            <tr>
              <th style={divTop} scope="p-bold">
                ITEM/DESCRIPTION
              </th>
              <th style={(divTop, costStyle)} scope="p-bold">
                COST (ex GST)
              </th>
              <th style={divTop} scope="p-bold">
                SELECT
              </th>
            </tr>
          </thead>

          <tbody>
            {this.props.scopeCollections.map((scopeCollection) => (
              <ScopeCollectionItem
                quoteVersion={this.props.quoteVersion}
                scopeCollection={scopeCollection}
                key={scopeCollection.id}
                changeSelectedForScopeCollection={
                  this.props.changeSelectedForScopeCollection
                }
                refreshList={this.props.refreshList}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

class ScopeCollectionItem extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // Change the cursor to the default if the proposal cannot be changed.
    // Done this way because react-animated-checkbox does not provide a way to change the cursor type, without editing the library itself.
    const checkboxes = document.querySelectorAll(
      "#quote-table > tbody > tr > td > div > label"
    );
    if (
      this.props.quoteVersion.quote_status.name != "Pending" &&
      this.props.quoteVersion.quote_status.name != "Quote Sent" &&
      this.props.quoteVersion.job_id != null
    ) {
      checkboxes.forEach((checkbox) => {
        checkbox.style.cursor = "default";
      });
    }
  }

  handleCheckClick() {
    this.props.changeSelectedForScopeCollection(this.props.scopeCollection.id);
  }

  render() {
    return (
      <tr className="mk-comm-quote-table-row comm-scope-wrap">
        <td>
          <p className="mb-0">
            <strong>{this.props.scopeCollection.name}</strong>
          </p>
        </td>

        <td className="pr-3">
          <p className="mb-0">
            $
            {formatMoney(
              this.props.scopeCollection.line_items.reduce(
                (acc, curr) => acc + Number(curr.price),
                0
              )
            )}
          </p>
        </td>

        <td>
          <div className="repayment-plan-checkbox">
            <input
              type="checkbox"
              onClick={() => this.handleCheckClick()}
              defaultChecked={this.props.scopeCollection.selected == 1}
              className="checkbox"
              id={`scopeCollectionCheckbox${this.props.scopeCollection.id}`}
            />
            <label
              htmlFor={`scopeCollectionCheckbox${this.props.scopeCollection.id}`}
            >
              <span className="checkmark"></span>
            </label>
          </div>
        </td>
      </tr>
    );
  }
}

class OptionalScopeCollectionsList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="table-responsive comm-prop-quote-table">
        <table className="table" id="quote-table">
          <thead>
            <tr>
              <th scope="col p-bold">SELECT:</th>
              <th scope="col p-bold">OPTIONAL ITEM:</th>
              <th scope="col p-bold">PRICE (excl. GST):</th>
            </tr>
          </thead>

          <tbody>
            {this.props.scopeCollections.map((scopeCollection) => (
              <OptionalScopeCollection
                scopeCollection={scopeCollection}
                key={scopeCollection.id}
                changeSelectedForScopeCollection={
                  this.props.changeSelectedForScopeCollection
                }
                refreshList={this.props.refreshList}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

class OptionalScopeCollection extends Component {
  constructor(props) {
    super(props);
  }

  handleCheckClick() {
    this.props.changeSelectedForScopeCollection(this.props.scopeCollection.id);
  }

  render() {
    const checkBoxView = this.props.scopeCollection.optional ? (
      <div>
        <input
          type="checkbox"
          onClick={() => this.handleCheckClick()}
          defaultChecked={this.props.scopeCollection.selected == 1}
          className="checkbox"
          id={`scopeCollectionCheckbox${this.props.scopeCollection.id}`}
        />
        <label
          htmlFor={`scopeCollectionCheckbox${this.props.scopeCollection.id}`}
        >
          <span className="checkmark"></span>
        </label>
      </div>
    ) : null;

    return (
      <tr className="mk-comm-quote-table-row border-bottom comm-scope-wrap">
        <td className="py-3">
          <div className="repayment-plan-checkbox">{checkBoxView}</div>
        </td>

        <td className="py-3">
          <p className="mb-0">{this.props.scopeCollection.name}</p>
        </td>

        <td className="py-3">
          <p className="mb-0">
            $
            {formatMoney(
              this.props.scopeCollection.line_items.reduce(
                (acc, curr) => acc + Number(curr.price),
                0
              )
            )}
          </p>
        </td>
      </tr>
    );
  }
}

if (document.getElementById("commercial-line-items-app")) {
  const element = document.getElementById("commercial-line-items-app");
  const props = { ...element.dataset };
  ReactDOM.render(
    <LineItemsAppCommercial {...props} />,
    document.getElementById("commercial-line-items-app")
  );
}
